<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: [],
  data() {
    return {};
  },
  // created () {},
  // mounted () {},
  // methods: {},
  watch: {
    $route(to, from) {
      if (from.path !== '/personal/myAssets/getCoinSite' && to.path !== '/personal/myAssets/getCoin') {
        this.$store.commit('myAssets/save', {
          defaultSite: '',
        });
      }
      if (from.path === '/personal/myAssets/getCoin' && to.path !== '/personal/myAssets/getCoinSite') {
        this.$store.commit('myAssets/save', {
          getCoinObj: '',
        });
      }
    },
  },
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less"></style>
